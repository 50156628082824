import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import { getEnvironmentURL } from "../_helpers";

export default function TpmsVehicleCheckPage(props) {
  const classes = useStyles();
  const [uploadType, setUploadType] = useState(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  const handleRejectFile = (file, errors) => {
    alert(file.path + " is not valid. " + errors[0].message);
  };

  const handleAddFile = (file = null, text = null) => {
    setLoading(true);
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
    } else if (text) {
      formData.append("text", text);
    }

    let url = `${getEnvironmentURL()}/api/reports/tpmsVehicleCheck`;
    if (uploadType === "file2") {
      url = `${getEnvironmentURL()}/api/reports/tpmsVehicleCheckFileInline`;
    }

    if (uploadType === "file2") {
      fetch(url, {
        // Your POST endpoint
        method: "POST",
        body: formData,
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "VehicleCheck.xlsx");
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        });
    }

    if (uploadType === "file" || uploadType === "text") {
      fetch(url, {
        // Your POST endpoint
        method: "POST",
        body: formData,
      })
        .then(
          (response) => response.json() // if the response is a JSON object
        )
        .then((data) => {
          // console.log("success", success); // Handle the success response object
          if (Array.isArray(data)) {
            setResults(data);
            setLoading(false);
          } else {
            setLoading(false);
            setResults(null);
            alert("Unexpected data");
          }
        })
        .catch((error) => {
          setLoading(false);
          setResults(null);
          alert(error);
        });
    }
  };

  return (
    <PageWithAppDrawer2 pageTitle="Non Compliant Vehicle Check">
      <Paper className={classes.paper}>
        <Typography variant="h6" color="primary">
          Non Compliant Vehicle Check
        </Typography>
        <Typography variant="h6" style={{color: "red"}}>
          DVS is not responsible for any decision to purchase
        </Typography>
        <Typography variant="body1">
          This service attempts to identify whether a vehicle is was not
          originally manufactured with FMVSS No. 138 compliant TPMS.
        </Typography>
        <Typography variant="body1">
          This service also attempts to identify whether a vehicle will have duty, and what that duty might be.
        </Typography>
        
        <Typography variant="body1">
          Our information is based upon the information in:
        </Typography>
        <a href="https://icsw.nhtsa.gov/cars/rules/import/newsletters/Revised%20RI%20Newsletter%2063.pdf">
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            REGISTERED IMPORTER NEWSLETTER No. 63
          </Typography>
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            National Highway TrafficSafety Administration
          </Typography>
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            Office of Vehicle Safety Compliance
          </Typography>
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            December 14, 2017
          </Typography>
        </a>
        <Typography variant="body1" style={{color: "red"}}>
          The results of this check are informational only and should be
          verified with FMVSS/NHTSA before purchasing vehicles for imporation from
          Canada to the United States
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 10 }}>
          {!results && !loading ? (
            <>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setUploadType("file")}
                >
                  Upload XLS/XLSX/CSV
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setUploadType("text")}
                >
                  Paste VINS
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => setUploadType("file2")}
                >
                  Get Annotated File from Upload
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setUploadType(null);
                  setResults(null);
                }}
              >
                Start Over
              </Button>
            </Grid>
          )}
        </Grid>

        {loading && <CircularProgress />}
        {!loading && !results && (
          <>
            {uploadType === "file" && (
              <DragAndDrop
                onAddFile={handleAddFile}
                onRejectFile={handleRejectFile}
              />
            )}
            {uploadType === "file2" && (
              <DragAndDrop
                onAddFile={handleAddFile}
                onRejectFile={handleRejectFile}
              />
            )}
            {uploadType === "text" && (
              <>
                <TextField
                  multiline
                  rows="10"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setText(e.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddFile(null, text)}
                >
                  Send Text VINs
                </Button>
              </>
            )}
          </>
        )}
        {!loading && results && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>VIN</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ fontWeight: "bold" }}>Valid</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{ fontWeight: "bold" }}>
                  TPMS Compliance (not verified)
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography style={{ fontWeight: "bold" }}>DUTY (not verfied)</Typography>
              </Grid>
              {results.map((item, index) => {
                let colorVin = "inherit";
                let colorValidVin = "inherit";
                let colorTpms = "inherit";
                let colorGst = "inherit";
                if (!item.validVin) colorVin = "red";
                if (item.tpmsCheck === "not compliant") colorTpms = "red";
                // if (item.tpmsCheck === null || item.tpmsCheck === "") colorTpms = "red";
                if (item.gstCheck.includes("25%")) colorGst = "red";
                if (!item.isAmerican) colorGst = "red";
                return (
                  <Fragment key={index}>
                    <Grid item xs={3} style={{ backgroundColor: colorVin }}>
                      {item.vin}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{ backgroundColor: colorValidVin }}
                    >
                      {item.validVin ? "Valid" : "Not Valid"}
                    </Grid>
                    <Grid item xs={3} style={{ backgroundColor: colorTpms }}>
                      {item.tpmsCheck || 'Unknown'}
                      <br/>
                      (not verified)
                    </Grid>
                    <Grid item xs={5} style={{ backgroundColor: colorGst }}>
                      {item.gstCheck}
                      <br/>
                      (not verified)
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        )}
      </Paper>
    </PageWithAppDrawer2>
  );
}

function DragAndDrop(props) {
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      props.onAddFile && props.onAddFile(file);
    });
    fileRejections.forEach((file) => {
      props.onRejectFile && props.onRejectFile(file.file, file.errors);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: [".csv", ".xlsx", ".xls"] });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop
          <br />
          csv, xls, xlsx
          <br />
          Or click to select files
        </p>
      </div>
    </div>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
